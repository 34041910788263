<template>
    <div class="px-4 py-1 stop">
      <div class="py-1 font-bold text-black border-b border-gray-300 title">
        <!-- {{ title }} -->
      </div>
      <div class="pt-2 content_item">
        <div class=" items-center justify-between">
          <div style='width:100%' class=" items-center item">
  
              <div style="width:80px;float:left;margin-left:00px;line-height: 40px;">充值单号：</div>
              <div  style="float:left;width:100px" class="py-2 pr-4 input_box">
                <a-input size="small" style="width:100px;height:24px" placeholder="请输入充值单号" v-model="ordersn" />
              </div>
              <!-- <div style="width:80px;float:left;margin-left:00px;line-height: 40px;">商户名称：</div> -->
              <!-- <div  style="float:left;width:100px" class="py-2 pr-4 input_box">
                <a-input size="small" style="width:100px;height:24px" placeholder="请输入确认码" v-model="merchant_title" />
              </div> 
              <div style="width:80px;float:left;margin-left:00px;line-height: 40px;">商品名称：</div>
              <div  style="float:left;width:100px" class="py-2 pr-4 input_box">
                <a-input size="small" style="width:100px;height:24px" placeholder="请输入确认码" v-model="good_name" />
              </div>
                         <div style="width:180px;float:left;margin-left:00px;line-height: 40px;"> 
                          <a-checkbox v-model:checked="checked">查看逾期未执行订单</a-checkbox></div>
  
              <div style="width:80px;clear:both;float:left;margin-left:00px;line-height: 40px;">确认码：</div>
              <div  style="float:left;width:100px" class="py-2 pr-4 input_box">
                <a-input size="small" style="width:100px;height:24px" placeholder="请输入确认码" v-model="code" />
              </div> -->
                          <div style="width:80px;float:left;margin-left:00px;line-height: 40px;">充值状态：</div>
              <div  style="float:left;width:100px;height:24px" class="py-2 pr-4 input_box">
                 <a-select
                ref="select"
                v-model:value="status"
                style="width: 100px;height:24px"
                
                @change="handleChange"
                >
                <a-select-option value="">全部</a-select-option>
                  <a-select-option value="-1">已取消</a-select-option>
                  <a-select-option value="0">未付款</a-select-option>
                  <a-select-option value="1">已付款</a-select-option>
          
                </a-select>
              </div>
                        
       
            <div style="width:500px;float:right;height:60px;margin-top:10px;"> 
            <a-button  style='float:right;width:48px;background-color:#294DEA;color:#fff' size="small" type="" @click="resetFunc" class="ml-2"
              >重置</a-button>
            <a-button @click= 'aquireLists'  style='float:right;width:48px;background-color:#294DEA;color:#fff' size="small" type="">查询</a-button>
   
            
            <div style='float:right;margin-top:-15px' class="pt-2 input_box">
              <div class="py-2 pr-4 input_box">
                <a-range-picker
                  size="small"
                  style="width: 250px;height:50px;"
                  :placeholder="['开始日期', '结束日期']"
                  :value="showDateTime"
                  @change="onChange"
                >
                  <template slot="dateRender" slot-scope="current">
                    <div
                      class="ant-calendar-date"
                      :style="getCurrentStyle(current)"
                    >
                      {{ current.date() }}
                    </div>
                  </template>
                </a-range-picker>
              </div>
            </div>
            <div style='float:right;'>采集时间：</div>
            </div> 
          </div>
        </div>
        <div style = 'background-color:#fff;margin-top:60px;' class="pt-4">
          <a-table v-if="table_show"
            :data-source="dataSource"
            :columns="columns"
            :scroll="{ x: 1000 }"
            :pagination="pagination" 
          >
          <template style="" slot="order_state" slot-scope="text, record">
            <!-- {{record.refund_status_text}}   -->
           
            <div  v-if="record.refund_status==0">{{record.refund_status_text}}正常</div>
            <a-button 
                  type="default"
                  v-if="record.refund_status==1"
                  @click="order_show(record.id,1)"
                  >退款审核中</a-button>
                  <a-button 
                  type="default"
                  v-if="record.refund_status==2"
                  @click="order_show(record.id,2)"
                  >已退款</a-button>
                  <a-button 
                  type="default"
                  v-if="record.refund_status==3"
                  @click="order_show(record.id,3)"
                  >拒绝退款申请</a-button>
            <!-- {{record.name}} -->
              
            </template>
            <template style="width:220px;" slot="operation" slot-scope="text, record">
              <!-- <div v-if="record.statusName!='已上传'" class="flex"> -->
                <div class="flex">
                <a-button
                  type="default"
                  class="mr-2"
                  @click="collectData(record.key,'','')"
                  >补单</a-button
                >
              </div>
  
            </template>
          </a-table>
        </div>
        <a-modal cancelText="关闭"   v-model:visible="visible" title="退款信息" >
        <p>{{refund_explain}}</p>
        <!-- <p>{{refund_status_text}}</p> -->
          <template slot = 'footer'>
            <a-button @click="handCancelgb">关闭</a-button>
          </template>
        </a-modal>
        <a-modal cancelText="拒绝" okText="同意" v-model:visible="visiblech" title="退款信息" @cancel="" @ok="">
        <p>{{refund_explain}}</p>
        <!-- <p>{{refund_status_text}}</p> -->
        <template slot = 'footer'>
            <a-button  @click="handCancel">拒绝</a-button>
            <a-button style="background-color:#509645;color:#fff" @click="handleOk">同意</a-button>
          </template>
  
        </a-modal>
      </div>
     
    </div>
  </template>
  
  <script>
  import {pay } from "@/api/collect";
  
  import { transformDate } from "@/utils/transformDate";
  
  export default {
    data() {
      return {
        member_id:'',
        table_show:true,
        ordersn:'',
        status:'',
        pay_type:1,
        value1:'',
        value2:'',
        refund_explain:'',
        put_ordersn:'',
        merchant_title:'',
        good_name:'',
        code:'',
        checked: false,
        visiblech:false,
        refund_status_text:'',
        showtype:'',
        visible:false,
        title: "",
        passid:0,
        titleObj: {
          local: "本地申请数据分析",
          web: "网页申请数据分析",
          month: "本月申请数据分析",
          year: "本年申请数据分析",
        },
        orderson:'',
  
        timearr:[],
        type: 2,
        typeObj: {
          local: 3,
          web: 4,
          month: 5,
          year: 6,
        },
        order: "",
        dataSource: [
        ],
        columns: [
          {
            title: "充值单号",
            dataIndex: "ordersn",
            // fixed: "left",
            width: 100,
          },
          { title: "客户ID", dataIndex: "member_id", width: 80 },
        //   {
        //     title: "客户充值帐号",
        //     dataIndex: "dateTime",
        //     width: 200,
        //   },
          {
            title: "充值金额",
            dataIndex: "money",
            width: 80,
          },
          {
            title: "充值时间",
            dataIndex: "create_time",
            width: 80,
          },
          {
            title: "充值状态",
            dataIndex: "statusName",
            width: 80,
          },
         
  
          {
            title: "操作",
            key: "operation",
             fixed: "right",
            width: 90,
            scopedSlots: { customRender: "operation" },
          },
        ],
        statusNameObj: {
          "-1": "已取消",
          0: "未付款",
          1: "已付款",
        },
        pagination: {
          // 关于页码的配置属性
          total: 0,
          pageSize:6,
          pageNo: 1,
          showSizeChanger: false, // 显示可改变每页数量
          pageSizeOptions: ["6"], // 每页数量选项
          showTotal: (total) => `共 ${total} 条`, // 显示总数
          onShowSizeChange: (current, pageSize) =>
            this.onSizeChange(current, pageSize), // 改变每页数量时更新显示
          onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
        },
        searchTime: [], // 查询的时间
        showDateTime: null, //默认给个null
      };
    },
    created() {
   
      this.aquireList();
    },
    watch: {

    },
    methods: {
      handleChange(e){
        console.log(e)
        this.status = e
      },

      order_show(id,type){
        this.passid = id
        refundInfo({id:id}).then(res=>{
          if(res.code == 200){
            this.refund_explain = res.data.refund_explain
            this.refund_status_text = res.data.refund_status_text
          }
        })
        this.showtype = type
        if(type ==1){
          this.visiblech = true
        }
        if(type ==2 || type ==3){
          this.visible = true
          
        }
  
    
      },
      handCancelgb(){
  
        this.visible = false
      },
      handCancel(){
        this.visiblech = false
        refuseRefund({id:this.passid}).then(res=>{
          if(res.code ==200){
            this.$message.success("拒绝成功");
            location.reload()
          }else{
            this.$message.error("拒绝失败");
          }
        })
      },
      handleOk(){
        this.visiblech = false
        
        passRefund({id:this.passid}).then(res=>{
          if(res.code ==200){
            this.$message.success("同意成功");
            
            location.reload()
          }else{
            this.$message.error("同意失败");
          }
        })
  },
      showConfirm(){
        Modal.confirm({
          title: () => 'Do you want to delete these items?',
          icon: () => createVNode(ExclamationCircleOutlined),
          content: () => 'When clicked the OK button, this dialog will be closed after 1 second',
          onOk() {
            return new Promise((resolve, reject) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.log('Oops errors!'));
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onCancel() {},
        });
      },
  
      repateData(key){  
              let index = this.dataSource.findIndex((item) => {
          return item.key == key;
        });
        let data = this.dataSource[index];
        console.log(data.id)
        //this.showConfirm()            
                    repateStatus({order_id:data.id,action:'reshoot'}).then(res=>{
                       console.log(res)
                       console.log(key)
                       if(res.code == 200){
                       this.collectData(key,data.id,'reset')
                       }else{
                        alert(res.msg)
                       }
                    })
  
  
      },
  
  
      getCurrentStyle(current, today) {
        const style = {};
        if (current.date() === 1) {
          style.border = "1px solid #1890ff";
          style.borderRadius = "50%";
        }
        return style;
      },
       // 搜索请求数据
        aquireLists() {
          this.table_show = false
 
          this.pagination.pageNo = 1
        let data = {
          

          page: this.pagination.pageNo,
          limit: this.pagination.pageSize,
          member_id:this.member_id,
          ordersn:this.ordersn,
          status :this.status,
         // pay_type:this.pay_type,
          time:this.timearr
        };
        this.searchTime.length > 0 ? (data.time = this.searchTime) : "";
        
        pay(data)
          .then((res) => {
            res.data.list.forEach((item, index) => {
              item.key = index;
              item.statusName = this.statusNameObj[item.status];
              item.dateTime = `${item.date}  ${item.start_time} ~ ${item.end_time}`;
            });
            this.dataSource = [];
            this.dataSource.push(...res.data.list);
            this.pagination.total = res.data.count;
          })
          .catch((error) => {
            console.log(error);
          });
          this.$nextTick(()=>{
            
            this.table_show = true
          })
      },
      // 请求数据
      aquireList() {
        let data = {
          
          page: this.pagination.pageNo,
          limit: this.pagination.pageSize,
          member_id:this.member_id,
          ordersn:this.ordersn,
          status :this.status,
       //   pay_type:this.pay_type,
          time:this.timearr
        //   ordersn :this.orderson,
        //   merchant_title :this.merchant_title,
        //   time:this.timearr
        };
        this.searchTime.length > 0 ? (data.time = this.searchTime) : "";
        pay(data)
          .then((res) => {
            res.data.list.forEach((item, index) => {
              item.key = index;
              item.statusName = this.statusNameObj[item.status];
              item.dateTime = `${item.date}  ${item.start_time} ~ ${item.end_time}`;
            });
            this.dataSource = [];
            this.dataSource.push(...res.data.list);
            this.pagination.total = res.data.count;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      change(e) {
        // console.log(e)
        this.page = e.current;
        this.aquireList();
      },
      // 分页里size改变触发的函数
      onSizeChange(_, pageSize) {
        this.pagination.pageNo = 1;
        this.pagination.pageSize = pageSize;
        this.aquireList();
      },
      // 页面改变时
      onPageChange(page, pageSize) {
        console.log(page, pageSize);
        this.pagination.pageNo = page;
        this.aquireList();
      },
      // 跳转到采集页面（数据录入）
      collectData(key,dataid,action) {
        return
        let index = this.dataSource.findIndex((item) => {
          return item.key == key;
        });
        let data = this.dataSource[index];
        console.log(data)
       // alert('aaa')
       localStorage.setItem('inputmodelid',data.model)
      // this.$store.commit("updateinputObject", data.model);
        this.$store.commit("setOrderInfo", {
          sn: data.ordersn,
          id: data.order_id,
          size: data.size,
        });
        
        localStorage.setItem(
          "orderInfo",
          JSON.stringify({
            sn: data.ordersn,
            id: data.id,
            size: data.size,
          })
        );
        // if(action != 'reset'){
          localStorage.setItem(
              "orderInfo",
              JSON.stringify({
                sn: data.ordersn,
                id: data.id,
                size: data.size,
                order_id: data.order_id,
              })
            );
            //alert(data.order_id)
            this.$router.push("/edit");
        // }else{
        //   updateStatus({
        //   order_id: dataid,
        //   order_status: 0,
        // }).then((res) => {
        //   if (res.code == 200) {
        //     localStorage.setItem(
        //       "orderInfo",
        //       JSON.stringify({
        //         sn: data.ordersn,
        //         id: data.id,
        //         size: data.size,
        //         order_id: dataid,
        //       })
        //     );
        //    this.$router.push("/edit");
        //   } else {
        //     alert('返回结果失败')
        //     this.$message.error(res.msg);
        //   }
        // });
        // }
  
        // params: { order_id: data.order_id, size: data.size }
  
      },
      resetFunc() {
        this.pagination.pageNo = 1;
        this.searchTime = [];
        this.showDateTime = null;
        this.member_id='',
         this.ordersn='',
          this.status='',
       //   pay_type:this.pay_type,
         this.timearr=='',
         this.table_show = false
        this.aquireList();
        this.$nextTick(()=>{
            
            this.table_show = true
          })
      },
      onChange(e) {
        this.showDateTime = e;
        let start = transformDate(e[0]);
        let end = transformDate(e[1]);
        this.searchTime = [];
        this.searchTime.push(start);
        this.searchTime.push(end);
        this.aquireList();
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  
  :global(.ant-select-selection__rendered){
    height: 24px !important;line-height: 24px !important;
  }
  :global(.ant-select-selection--single){
    height: 24px !important;line-height: 24px !important;
  }
  
  .ant-input-sm{height: 24px !important;}
  .ant-select-selection-single{height: 24px !important;line-height: 24px !important;}
  .title {
    font-size: 20px;
  }
  .input_box {
    width: 250px;
    margin-right: 20px;
  }
  
  .content_item_title {
    font-size: 16px;
    color: #000000;
  }
  .select_info {
    background: #afe8bc;
    .etc-gantanhao-copy-copy {
      font-size: 18px;
    }
    span {
      font-size: 14px;
    }
    i {
      font-style: normal;
      padding: 0px 2px;
      color: @primary-color;
    }
    .delete {
      color: red;
      user-select: none;
    }
  }
  </style>
  <style lang="less">
  .stop {
    .circle {
      display: inline-block;
      height: 6px;
      width: 6px;
      background: @primary-color;
      border-radius: 50%;
    }
    tr td:last-child {
      display: flex;
      justify-content: flex-end;
      a:nth-child(1) {
        color: @primary-color;
      }
      a:nth-child(2) {
        color: red;
      }
    }
    th:last-child {
      text-align: right;
    }
  
    .ant-btn {
      display: flex;
      align-items: center;
    }
  }
  </style>
  